<template>
  <div class="objects-calendar relative">
    <span class="input-label">{{ label }}</span>
    <el-date-picker
      v-model="model"
      ref="dateTimePicker"
      :type="type"
      :format="format"
      :value-format="valueFormat"
      range-separator="-"
      @change="$emit('onChange', $event)"
    />
    <CalendarIcon
      class="objects-calendar__icon absolute cursor-pointer"
      @click.native="openPicker"
    />
  </div>
</template>
<script setup>
import { ref, onBeforeMount, watch } from 'vue'

import { useI18n } from '@/hooks/useI18n'

import moment from 'moment'

import CalendarIcon from '@/components/unitsNew/assets/icons/calendar.vue'

const $t = useI18n()

const props = defineProps({
  format: {
    type: String,
    default() {
      return 'HH:mm:ss dd/MM/yyyy'
    },
    required: false
  },
  valueFormat: {
    type: String,
    default() {
      return 'yyyy-MM-dd HH:mm:ss'
    },
    required: false
  },
  type: {
    type: String,
    default() {
      return 'datetimerange'
    }
  },
  label: {
    type: String,
    default: ''
  },
  date: {
    type: Array | String,
    default: () => []
  }
})

const dateTimePicker = ref(null)
const isDateTimePickerOpen = ref(false)

const emits = defineEmits(['onChange'])
const defaultFormat = 'yyyy-MM-DD HH:mm:ss'

const currentDay = moment(new Date()).format(defaultFormat)
const prevMonth = moment(new Date()).subtract(1, 'month').format(defaultFormat)

const defaultTime = [prevMonth, currentDay]

const label =
  props.label || $t('new_units.objects.fields.couplings.date_filter')

const model = ref(null)

watch(
  () => props.date,
  (date) => {
    model.value = date === $t('new_units.unspecified') ? '' : props.date
  },
  { immediate: true }
)

const openPicker = () => {
  if (isDateTimePickerOpen.value) {
    isDateTimePickerOpen.value = false
    dateTimePicker.value.hidePicker()
  } else {
    isDateTimePickerOpen.value = true
    dateTimePicker.value.showPicker()
  }
}

onBeforeMount(() => {
  props.type === 'datetime'
    ? (model.value =
        props.date === $t('new_units.unspecified') ? '' : props.date)
    : (model.value = props.date.length || defaultTime)

  console.log(model.value, 'model', props.type)
  if (props.type === 'datetimerange') {
    emits('onChange', model.value)
  }
})
</script>
<style lang="scss">
@import '@/components/unitsNew/assets/styles/UnitsDateTimePicker.scss';
</style>
