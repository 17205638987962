<template>
	<el-form :model="shiftInfo" :ref="refs.shiftsValidation" class="flex flex-col gap-5">
		<div class="overflow-auto object-shifts__details flex flex-col gap-4 p-5">
			<div class="relative">
				<span class="objects-shifts__info__label input-label">
					{{ $t('new_units.objects.fields.shifts.driver') }}
				</span>
				<el-form-item :rules="rules['user.id']" prop="user.id">
					<el-select :value="shiftInfo.user.id" :placeholder="$t('new_units.placeholder.select')" @change="changeShift">
						<el-option
							v-for="(driver, idx) in drivers"
							:key="idx"
							:label="driver.name"
							:value="driver.id"
						/>
					</el-select>
				</el-form-item>
			</div>
			<el-form-item :rules="rules.date_from" prop="date_from">
				<UnitsDateTimePicker
					:date="shiftInfo.date_from"
					:label="$t('new_units.objects.fields.shifts.shift_start')"
					:class="{ 'error': errors.includes('date_from') }"
					type="datetime"
					format="dd/MM/yyyy HH:mm"
					@onChange="shiftInfo.date_from = $event"
				/>
			</el-form-item>
			
			<el-form-item :rules="rules.date_to" prop="date_to">
				<UnitsDateTimePicker
					:date="shiftInfo.date_to"
					:label="$t('new_units.objects.fields.shifts.shift_end')"
					:class="{ 'error': errors.includes('date_to') }"
					type="datetime"
					format="dd/MM/yyyy HH:mm"
					@onChange="shiftInfo.date_to = $event"
				/>
			</el-form-item>
			<div v-if="errors.length" class="object-couplings__details__note flex items-center gap-3 text-xs font-normal rounded-xl">
				<NoteInfoWarnIcon />
				<span>
					{{ $t('new_units.objects.fields.shifts.note') }}
				</span>
			</div>
			<ShiftsAdditionalFields
				:fields="shiftInfo.custom_fields"
				@onDelete="deleteField"
				@onAdd="addField"
			/>
			<ObjectsEditFooter v-if="!shift.isNew" @onEdit="$emit('onUpdate')" @onCancel="$emit('onCancel', originalShift)" />
			<UnitsApproveButton v-else @onApprove="$emit('onApprove')" />
		</div>
	</el-form>
</template>
<script setup>
import { ref, watch } from 'vue';

import { createVuexHelpers } from 'vue2-helpers';
import { useI18n } from '@/hooks/useI18n';
import { refs } from '@/components/unitsNew/helpers/index.js';

import UnitsDateTimePicker from '@/components/unitsNew/components/UnitsDateTimePicker.vue';
import NoteInfoWarnIcon from '@/components/unitsNew/assets/icons/note-info_warn.vue';
import ShiftsAdditionalFields from './ShiftsAdditionalFields.vue';
import UnitsApproveButton from '@/components/unitsNew/components/UnitsApproveButton.vue';
import ObjectsEditFooter from '@/components/unitsNew/components/Objects/ObjectsEditFooter.vue';

const { useGetters } = createVuexHelpers();

const { getDrivers } = useGetters('users', ['getDrivers']);

const drivers = getDrivers.value

const $t = useI18n();

const props = defineProps({
	shift: {
		type: Object,
		default: () => ({})
	},
	errors: {
		type: Array,
		default: () => []
	}
})

const rules = ref({
	'user.id': [{ required: true, message: $t('new_units.objects.fields.shifts.errors.driver') }],
	date_from: [{ required: true, message: $t('new_units.objects.fields.shifts.errors.shift_start') }],
	date_to: [{ required: true, message: $t('new_units.objects.fields.shifts.errors.shift_end') }],
})

const shiftInfo = ref({})
const originalShift = ref({})

const changeShift = (shift) => {
	props.shift.user = getDrivers.value.find(item => item.id === shift)
}


watch(() => props.shift, (shift) => {
	if (Object.keys(props.shift).length) {
		shiftInfo.value = shift
		originalShift.value = JSON.parse(JSON.stringify(shift))
	}
}, { immediate: true })

const deleteField = (idx) => {
	props.shift.custom_fields.splice(idx, 1)
}

const addField = () => {
	props.shift.custom_fields.push({
		name: '',
		value: ''
	})
}
</script>
