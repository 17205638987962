<template>
	<div class="text-center bg-blue-100 rounded-md cursor-pointer p-1" @click="$emit('onApprove')">
		<span class="text-sm curiousBlue">{{ $t('new_units.approve') }}</span>
	</div>
</template>
<script setup>
import { useI18n } from '@/hooks/useI18n';

const $t = useI18n();

</script>
