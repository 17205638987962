<template>
	<div class="object-shifts__details__additional-fields flex flex-col gap-2">
		<span class="object-shifts__details__title">{{ $t('new_units.objects.fields.shifts.additionalFields') }}</span>
		<div class="flex flex-col gap-4 overflow-auto object-shifts__details__additional-fields__block">
			<div v-for="(field, idx) in fields" :key="idx"
				class="flex justify-between items-center object-shifts__details__additional-fields__items gap-2">
				<div class="flex flex-col gap-2 w-full">
					<div class="relative">
						<span class="object-shifts__details__label input-label">{{ $t('new_units.objects.fields.shifts.name') }}</span>
						<el-input v-model="field.name" />
					</div>
					<div class="relative">
						<span class="object-shifts__details__label input-label">{{ $t('new_units.objects.fields.shifts.value') }}</span>
						<el-input v-model="field.value" />
					</div>
				</div>
				<div class="object-shifts__details__additional-fields__remove cursor-pointer" @click="$emit('onDelete', idx)">
					<DeleteIcon />
				</div>
			</div>
		</div>
		<UnitsAddButton active-tab="add_field" class="object-shifts__details__additional-fields__add"
			@click.native="$emit('onAdd')" />
	</div>
</template>

<script setup>
import { ref } from 'vue';

import UnitsAddButton from '@/components/unitsNew/components/UnitsAddButton.vue';
import DeleteIcon from '@/components/unitsNew/assets/icons/delete.vue';

import { useI18n } from '@/hooks/useI18n'

const $t = useI18n()

const props = defineProps({
	fields: {
		type: Array,
		default: () => []
	}
})
</script>
